import React, { useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import "./../../../SCSS/ResponsivePages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Component/InputFields/TextArea/TextArea";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import { toast } from "react-toastify";
import { COMMON_FORM_SERVICES } from "../../../Services/Common/CommonServices";

function Claim() {
  const isMobile = useIsMobile();

  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    claimfor: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
    policy_number: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    claimfor: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
    policy_number: { value: "", warn_status: false },
  });

  const claimForData = [
    {
      label: "Health Insurance",
      value: "Health Insurance",
    },
    {
      label: "Term Insurance",
      value: "Term Insurance",
    },
    {
      label: "Car Insurance",
      value: "Car Insurance",
    },
    {
      label: "Bike Insurance",
      value: "Bike Insurance",
    },
  ];

  // const updateFieldsState = (attrName: string, value: string) => {
  //   setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  // };
  const updateFieldsState = (attrName: string, value: string) => {
    if (attrName === "policy_number") {
      value = value.trim();

      if (!/^[A-Z0-9/-]*$/.test(value)) return;

      if (value.includes("//") || value.includes("--")) return;
    }

    setFormFields((prev) => ({
      ...prev,
      [attrName]: { value: value },
    }));
  };

  const Submit_claim = () => {
    const toastID = toast.loading("Please wait...");
    let params = {
      name: formFields.name.value,
      email: formFields.email.value,
      mobile: formFields.mobile.value,
      claim: formFields.claimfor.value,
      request: formFields.message.value,
      policy_no: formFields.policy_number.value,
    };
    const onSuccess = (data: any) => {
      toast.dismiss(toastID);
      const results = data.status;
      const error = results;
      if (error) {
        toast.error("Something went wrong.");
      } else {
        setFormFields({
          name: { value: "", warn_status: false },
          email: { value: "", warn_status: false },
          mobile: { value: "", warn_status: false },
          claimfor: { value: "", warn_status: false },
          message: { value: "", warn_status: false },
          policy_number: { value: "", warn_status: false },
        });
        toast.success(
          "Thank you for your query! Our team will reach out to you shortly."
        );
      }
    };
    const onError = () => {};
    COMMON_FORM_SERVICES.Submit_claim(onSuccess, onError, params);
  };

  const validateForm = () => {
    let data = { ...formFields };
    data = {
      ...data,
      name: {
        ...data.name,
        warn_status: isEmpty(data.name.value),
      },
      email: {
        ...data.email,
        warn_status: !validateEmail(data.email.value),
      },
      mobile: {
        ...data.mobile,
        warn_status: !validateMobileNumber(data.mobile.value),
      },
      claimfor: {
        ...data.claimfor,
        warn_status: isEmpty(data.claimfor.value),
      },
      message: {
        ...data.message,
        warn_status: isEmpty(data.message.value),
      },
      policy_number: {
        ...data.policy_number,
        warn_status: isEmpty(data.policy_number.value),
      },
    };
    setFormFields(data);
    const isValid = Object.values(data).every(
      (field) => field.warn_status === false
    );

    if (isValid) {
      Submit_claim();
    }
  };
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox disclaimer">
              <h1 style={{ textAlign: "center" }}>Claim</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="form-section">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <h2 style={{ textAlign: "center", marginBottom: "12px" }}>
              Request to file a claim ticket.
            </h2>
            <p style={{ marginBottom: "24px", textAlign: "center" }}>
              Kindly provide your policy information, and we'll be here to
              support you with your claim.
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={7} textAlign={"center"} className="mb-margin2">
            <img
              src="./images/claim-img.svg"
              alt="contact"
              className="claim-img"
            />
          </Grid>
          <Grid xs={12} md={5} className="formbox">
            <RKTextField
              class_name="inputField mb-5"
              title={"Name"}
              validation_type="NAME"
              value={formFields.name.value}
              attrName={"name"}
              value_update={updateFieldsState}
              warn_status={formFields.name.warn_status}
              error_message={"Enter Your Name"}
            />
            <RKTextField
              class_name="inputField mb-5"
              title={"Mobile"}
              value={formFields.mobile.value}
              validation_type="NUMBER"
              max_length={10}
              attrName={"mobile"}
              value_update={updateFieldsState}
              warn_status={formFields.mobile.warn_status}
              error_message={
                !isEmpty(formFields.mobile.value)
                  ? "Enter Valid Mobile Number"
                  : "Enter Mobile Number"
              }
            />
            <RKTextField
              class_name="inputField mb-6"
              title={"Email"}
              value={formFields.email.value}
              attrName={"email"}
              value_update={updateFieldsState}
              warn_status={formFields.email.warn_status}
              error_message={
                isEmpty(formFields.email.value)
                  ? "Enter Email"
                  : "Enter Valid Email"
              }
            />
            <RKTextField
              class_name="inputField mb-5"
              title={"Policy Number"}
              max_length={20}
              value={formFields.policy_number.value}
              attrName={"policy_number"}
              value_update={updateFieldsState}
              warn_status={formFields.policy_number.warn_status}
              error_message={
                isEmpty(formFields.email.value)
                  ? "Enter Policy Number"
                  : "Enter Valid Policy Number"
              }
            />

            <SelectDropdown
              class_name="inputField mb-5"
              title="Claim ticket for"
              attrName={"claimfor"}
              value={formFields.claimfor.value}
              value_update={updateFieldsState}
              data={claimForData}
              warn_status={formFields.claimfor.warn_status}
              // error_message={"Please Write Something"}
            />

            <TextArea
              class_name="inputField mb-5"
              title={""}
              value={formFields.message.value}
              attrName={"message"}
              value_update={updateFieldsState}
              warn_status={formFields.message.warn_status}
              placeholder="Write here..."
              error_message={"Please Write Something"}
            />
            <CustomButton
              className="primaryBtn"
              text="Submit Details"
              onClick={() => {
                validateForm();
              }}
              fullWidth={false}
              variant="contained"
              // disable={true}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="contactDetails-section">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            {isMobile ? (
              <h2 style={{ textAlign: "center" }}>
                For quick help, contact our customer support now.
              </h2>
            ) : (
              <h2 style={{ textAlign: "center" }}>
                For quick help, contact our customer
                <br /> support now.
              </h2>
            )}
          </Grid>
        </Grid>
        {isMobile ? (
          <Grid
            container
            columnSpacing={3}
            className="row"
            alignItems={"center"}
          >
            <Grid xs={12}>
              <ul className="mobile_cdlist">
                <li>
                  <span>
                    <AlternateEmailIcon />
                  </span>
                  <Box>
                    {" "}
                    <h6 className="mb-2">Email</h6>
                    <a href="mailto:principalofficer@muthootgroup.com">
                      principalofficer@muthootgroup.com
                    </a>
                  </Box>
                </li>
                <li>
                  <span>
                    <PhoneIcon />
                  </span>
                  <Box>
                    <h6 className="mb-2">Phone</h6>
                    <a href="tel:+91 8590223204">+91 8590223204</a>
                  </Box>
                </li>
                <li>
                  <span>
                    <AccessTimeIcon />
                  </span>
                  <Box>
                    <h6 className="mb-2">Time</h6>
                    <p>9 a.m to 5:30 p.m on all working days.</p>
                  </Box>
                </li>
              </ul>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            columnSpacing={3}
            className="row"
            alignItems={"center"}
          >
            <Grid xs={12}>
              <ul className="cd-list2">
                <li>
                  <Box className="li-inner">
                    <span>
                      <AlternateEmailIcon />
                    </span>
                    <h6 className="mb-2">Email</h6>
                    <a href="mailto:principalofficer@muthootgroup.com">
                      principalofficer@muthootgroup.com
                    </a>
                  </Box>
                </li>
                <li>
                  <Box className="li-inner">
                    <span>
                      <PhoneIcon />
                    </span>
                    <h6 className="mb-2">Phone</h6>
                    <a href="tel:+91 8590223204">+91 8590223204</a>
                  </Box>
                </li>
                <li>
                  <Box className="li-inner">
                    <span>
                      <AccessTimeIcon />
                    </span>
                    <h6 className="mb-2">Time</h6>
                    <p>9 a.m to 5:30 p.m on all working days.</p>
                  </Box>
                </li>
              </ul>
            </Grid>
          </Grid>
        )}
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Claim;
