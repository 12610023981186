import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { HealthURLs } from "../../Routing/URLS/Health/HealthURL";
import { CommonURLs } from "../../URLCollection/Common/CommonURLs";
import { ADD_HEALTH_FORM_DTO } from "../DTO/HealthDTO/AddHealthFormDTO";

const Submit_claim = (
    onSuccess: (data: any) => void,
    onError: (data: any) => void,
    dto: any
  ) => {
    PostAPI.call(
      CommonURLs.CLAIM,
      dto,
      (res: any) => onSuccess(res.data),
      onError
    );
  };
export const COMMON_FORM_SERVICES = {
    Submit_claim,
};
